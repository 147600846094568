import React, { useState } from "react";
import "../styles/login.css";
import stateManager from "../components/stateManager";
import { LoginComponent } from "../components/loginConponent";

export const LoginPage = () => {
  const [user, setUser] = useState(null);

  return (
    <div>
      {user ? (
        <div>
          <h2>Você está logado</h2>
          <p>Bem-vindo, {user.name}!</p>
          <button onClick={() => setUser(null)}>Logout</button>
        </div>
      ) : (
        <LoginComponent setUser={setUser} />
      )}
    </div>
  );
};
