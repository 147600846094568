import React from "react";
// import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import MainRoutes from "./router.jsx";
import { createRoot } from "react-dom/client";
import MyComponent from "./pages/MyComponent.js";

const queryClient = new QueryClient();

const root = createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <MainRoutes />
    <MyComponent />
  </QueryClientProvider>
);
