// import React from "react";
// import { Link } from "react-router-dom";
// import "../styles/speechCard.css";
// import { useState } from "react";

// export const SpeechCard = ({ speech }) => {
//   return (
//     <div className="card">
//       <img
//         src="https://fakeimg.pl/350x150/"
//         className="card-img-top"
//         alt="..."
//       />
//       <div className="card-body">
//         <h5 className="card-title">{speech.speechTitle}</h5>
//         <p className="card-speecher">{speech.speecher}</p>
//         <p className="card-text">{speech.speecherTitle}</p>
//         <Link to={`/discurso/${speech.id}`} className="card-btn">
//           Leia mais
//         </Link>
//       </div>
//     </div>
//   );
// };

import React from "react";
import { Link } from "react-router-dom";
import "../styles/speechCard.css";

export const SpeechCard = ({ speech }) => {
  const getImagePath = (speecher) => {
    try {
      return require(`../../public/data/speech/${speecher}.jpg`);
    } catch (error) {
      // Se a imagem não for encontrada, use uma imagem padrão
      // return require("https://fakeimg.pl/350x150/");
    }
  };

  const photo = getImagePath(speech.speecher);

  return (
    <div className="card">
      <div className="card-img">
        <img src={photo} className="card-img-top" alt="..." />
      </div>
      <div className="card-body">
        <h5 className="card-title">{speech.speechTitle}</h5>
        <p className="card-speecher">{speech.speecher}</p>
        <p className="card-text">{speech.speecherTitle}</p>
        <Link to={`/discurso/${speech.id}`} className="card-btn">
          Leia mais
        </Link>
      </div>
    </div>
  );
};
