import React from "react";
import { Link } from "react-router-dom";
import "../styles/speechList.css";
import { GrFormNext } from "react-icons/gr";

export const SpeechList = ({ speech }) => {
  const getImagePath = (speecher) => {
    try {
      return require(`../../public/data/speech/${speecher}.jpg`);
    } catch (error) {
      // Se a imagem não for encontrada, use uma imagem padrão
      // return require("https://fakeimg.pl/350x150/");
    }
  };

  const photo = getImagePath(speech.speecher);

  return (
    <div className="list">
      <div className="list-img">
        <img src={photo} className="list-img-top" alt="..." />
      </div>
      <div className="list-body">
        <h3 className="list-title">{speech.speechTitle}</h3>
        <p className="list-speecher">{speech.speecher}</p>
        <p className="list-text">{speech.speecherTitle}</p>
      </div>
      <Link to={`/discurso/${speech.id}`} className="list-btn">
        <GrFormNext />
      </Link>
    </div>
  );
};
