import React, { useState, useEffect } from "react";
import "../styles/events.css";
import { EventCard } from "../components/eventCard.js";

export const Events = () => {
  const [calendar, setCalendar] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/data/calendar/calendar.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const jsonData = await response.json();
        setCalendar(jsonData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchData();
  }, []);

  const activities = calendar;

  // Estados para os filtros e ordenação
  const [selectedCapelas, setSelectedCapelas] = useState([]);
  const [orderBy, setOrderBy] = useState("");

  // Função para obter capelas únicas
  const uniqueCapelas = [...new Set(activities.map((event) => event.place))];

  // Função para lidar com a seleção de capelas
  const handleCapelaChange = (event) => {
    const { value, checked } = event.target;
    if (value === "Todos") {
      setSelectedCapelas(checked ? uniqueCapelas : []);
    } else {
      setSelectedCapelas((prevSelectedCapelas) =>
        checked
          ? [...prevSelectedCapelas, value]
          : prevSelectedCapelas.filter((capela) => capela !== value)
      );
    }
  };

  // Função para lidar com a mudança de ordenação
  const handleOrderByChange = (event) => {
    setOrderBy(event.target.value);
  };

  // Função para filtrar e ordenar eventos
  const getFilteredAndSortedEvents = () => {
    let filteredEvents = activities;

    if (selectedCapelas.length > 0 && !selectedCapelas.includes("Todos")) {
      filteredEvents = filteredEvents.filter((event) =>
        selectedCapelas.includes(event.place)
      );
    }

    if (orderBy) {
      filteredEvents = filteredEvents.sort((a, b) => {
        if (orderBy === "local") {
          return a.place.localeCompare(b.place);
        } else if (orderBy === "organização") {
          return a.organiser.localeCompare(b.organiser);
        } else if (orderBy === "data") {
          return new Date(a.date) - new Date(b.date);
        }
        return 0;
      });
    }

    return filteredEvents;
  };

  const filteredAndSortedEvents = getFilteredAndSortedEvents();

  return (
    <div className="event-body">
      <div className="event-display">
        <h1>Lista de Atividades</h1>
        <hr />
      </div>
      <div className="events-menu-bar">
        <div className="event-menu-chapel">
          <div className="event-menu-checkbox-all">
            {uniqueCapelas.map((capela) => (
              <label className="event-menu-label" key={capela}>
                <input
                  type="checkbox"
                  value={capela}
                  checked={selectedCapelas.includes(capela)}
                  onChange={handleCapelaChange}
                />
                {capela}
              </label>
            ))}
          </div>
        </div>
        <div>
          <label htmlFor="ordenar">Organizar por</label>
          <select id="ordenar" onChange={handleOrderByChange}>
            <option value="">Selecione</option>
            <option value="local">Local</option>
            <option value="organização">Organização</option>
            <option value="data">Data</option>
          </select>
        </div>
      </div>
      <span className="mt-3 bg-danger" />
      <div className="event-list">
        <ul className="event-ul">
          {filteredAndSortedEvents.length > 0 ? (
            filteredAndSortedEvents.map((event) => (
              <li key={event.id}>
                <EventCard event={event} />
              </li>
            ))
          ) : (
            <li>Carregando...</li>
          )}
        </ul>
      </div>
    </div>
  );
};
