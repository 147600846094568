// import $ from "jquery";

const stateManager = {
  speech: [],
  calendar: [],
  users: [],
  URL_SERVER: ["/data/speech/speech.json"],

  fetchInfo: async function (page) {
    // const url = window.URL_SERVER;
    const url = this.URL_SERVER;
    const baseUrl = `${url}/${page}`;
    // console.log(url, baseUrl);

    try {
      const response = await fetch(baseUrl);
      const data = await response.json();

      if (Array.isArray(data)) {
        if (page === "speech") {
          const speechesById = {};
          data.forEach((speech) => {
            speechesById[speech.id] = speech;
          });
          this.speech = speechesById;
        } else if (page === "calendar") {
          this.calendar = data;
        } else if (page === "users") {
          this.users = data;
        }
      } else {
        console.error("Resposta não é um array:", data);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  },

  fetchData: async function (page, id) {
    const url = window.URL_SERVER;
    const baseUrl = `${url}/${page}/${id}`;

    try {
      const response = await fetch(baseUrl);
      const data = await response.json();

      if (page === "speech") {
        this.speech = data;
      } else if (page === "calendar") {
        this.calendar = data;
      } else if (page === "users") {
        this.users = data;
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  },

  createUser: async function (userData) {
    const url = this.URL_SERVER + "/signup";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error("Erro ao criar usuário");
      }

      return await response.json();
    } catch (error) {
      console.error("Erro ao criar usuário:", error);
      throw error;
    }
  },

  updateUser: async function (userId, userData) {
    const url = `${this.URL_SERVER}/users/${userId}`;

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error("Erro ao atualizar usuário");
      }

      return await response.json();
    } catch (error) {
      console.error("Erro ao atualizar usuário:", error);
      throw error;
    }
  },

  loginUser: async function (credentials) {
    const url = this.URL_SERVER + "/login";
    console.log(credentials);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });

      if (!response.ok) {
        throw new Error("Credenciais inválidas");
      }

      return await response.json();
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      throw error;
    }
  },

  // createUser: async function (userData) {
  //   const url = "http://localhost:3001" + "/signup";
  //   console.log(url);
  //   console.log("user data", userData);
  //   try {
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(userData),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Erro ao criar usuário");
  //     }

  //     return await response.json();
  //   } catch (error) {
  //     console.error("Erro ao criar usuário:", error);
  //     throw error;
  //   }
  // },
};

export default stateManager;
