// MyComponent.js

import React, { useState, useEffect } from "react";
import stateManager from "../components/stateManager";

function MyComponent() {
  // const [speech, setSpeech] = useState(null);
  // const [calendar, setCalendar] = useState(null);

  // useEffect(() => {
  //   stateManager
  //     .fetchInfo("speech")
  //     .then(() => setSpeech(stateManager.speech))
  //     .catch(console.error);

  //   stateManager
  //     .fetchInfo("calendar")
  //     .then(() => setCalendar(stateManager.calendar))
  //     .catch(console.error);
  // }, []); // Run once on component mount

  return (
    <div>
      {/* <h2>Speech Data:</h2>
      {speech && (
        <ul>
          {speech.map((item) => (
            <li key={item.id}>{item.content}</li>
          ))}
        </ul>
      )}

      <h2>Calendar Data:</h2>
      {calendar && (
        <ul>
          {calendar.map((item) => (
            <li key={item.id}>{item.date}</li>
          ))}
        </ul>
      )} */}
    </div>
  );
}

export default MyComponent;
