// import { PiGridFourFill } from "react-icons/pi";
// import { PiGridFour } from "react-icons/pi";
// import { PiListFill } from "react-icons/pi";
// import { PiList } from "react-icons/pi";
// import React, { useEffect } from "react";
// import "../styles/speech.css";
// import { SpeechCard } from "../components/speechCard.js";
// import { SpeechList } from "../components/speechList.js";
// // import stateManager from "../components/stateManager.js";
// import { useState } from "react";
// // import { urlSpeech } from "../data/speech/speech.json";

// export const Speech = () => {
//   // const speeches = Object.values(stateManager.speech);
//   const [speeches, setSpeeches] = useState();
//   const [gridActive, setGridActive] = useState(true);
//   const [rowActive, setRowActive] = useState(false);

//   const handleGrid = () => {
//     setGridActive(true);
//     setRowActive(false);
//   };

//   const handleRow = () => {
//     setGridActive(false);
//     setRowActive(true);
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch("../data/speech/speech.json");
//         const jsonData = await response.json();
//         setSpeeches(jsonData);
//       } catch (error) {
//         console.error("Erro ao buscar dados:", error);
//       }
//     };
//   });

//   console.log("speech page response", speeches);

//   return (
//     <div className="speech-body discurso">
//       <div className="speech titleList">
//         <h1> Lista de Discursos</h1>
//         <hr />
//       </div>
//       <div className="speechMenu">
//         <div>
//           <input
//             className="search-input"
//             type="search"
//             placeholder="Pesquisar"
//           />
//           <select name="orderBy">
//             <option value="recente" defaultValue>
//               Mais recente
//             </option>
//             <option value="antigo">Mais antigo</option>
//             <option value="Crescente">Alfabetica</option>
//           </select>
//         </div>
//         <div className="btnMenu">
//           <span
//             className={gridActive ? "btn-active display" : "display"}
//             onClick={handleGrid}
//           >
//             {gridActive ? <PiGridFourFill /> : <PiGridFour />}
//           </span>
//           <span
//             className={rowActive ? "btn-active display" : "display"}
//             onClick={handleRow}
//           >
//             {rowActive ? <PiListFill /> : <PiList />}
//           </span>
//         </div>
//       </div>

//       <div className={rowActive ? "speech-row" : "speech-grid"}>
//         <ul className={rowActive ? "speech-ul-row" : "speech-ul-grid"}>
//           {speeches.length > 0 ? (
//             speeches.map((speech) => (
//               <li key={speech.id}>
//                 {gridActive ? (
//                   <SpeechCard speech={speech} />
//                 ) : (
//                   <SpeechList speech={speech} />
//                 )}
//               </li>
//             ))
//           ) : (
//             <li>loading...</li>
//           )}
//         </ul>
//       </div>
//     </div>
//   );
// };
import { PiGridFourFill } from "react-icons/pi";
import { PiGridFour } from "react-icons/pi";
import { PiListFill } from "react-icons/pi";
import { PiList } from "react-icons/pi";
import React, { useEffect, useState } from "react";
import "../styles/speech.css";
import { SpeechCard } from "../components/speechCard.js";
import { SpeechList } from "../components/speechList.js";

export const Speech = () => {
  const [speeches, setSpeeches] = useState([]);
  const [gridActive, setGridActive] = useState(true);
  const [rowActive, setRowActive] = useState(false);

  const handleGrid = () => {
    setGridActive(true);
    setRowActive(false);
  };

  const handleRow = () => {
    setGridActive(false);
    setRowActive(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/data/speech/speech.json");
        // console.log("Response:", response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        // Testando o conteúdo da resposta antes de convertê-la para JSON
        const textData = await response.text();
        // console.log("Text data:", textData);

        // Verifica se o conteúdo é um JSON válido
        try {
          const jsonData = JSON.parse(textData);
          setSpeeches(jsonData);
        } catch (error) {
          // console.error("Erro ao converter para JSON:", error);
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchData();
  }, []);

  // console.log("speech page response", speeches);

  return (
    <div className="speech-body discurso">
      <div className="speech titleList">
        <h1>Lista de Discursos</h1>
        <hr />
      </div>
      <div className="speechMenu">
        <div>
          {/* <input
            className="search-input"
            type="search"
            placeholder="Pesquisar"
          /> */}
          <select name="orderBy">
            <option value="recente" defaultValue>
              Mais recente
            </option>
            <option value="antigo">Mais antigo</option>
            <option value="Crescente">Alfabetica</option>
          </select>
        </div>
        <div className="btnMenu">
          <span
            className={gridActive ? "btn-active display" : "display"}
            onClick={handleGrid}
          >
            {gridActive ? <PiGridFourFill /> : <PiGridFour />}
          </span>
          <span
            className={rowActive ? "btn-active display" : "display"}
            onClick={handleRow}
          >
            {rowActive ? <PiListFill /> : <PiList />}
          </span>
        </div>
      </div>

      <div className={rowActive ? "speech-row" : "speech-grid"}>
        <ul className={rowActive ? "speech-ul-row" : "speech-ul-grid"}>
          {speeches.length > 0 ? (
            speeches.map((speech) => (
              <li key={speech.id}>
                {gridActive ? (
                  <SpeechCard speech={speech} />
                ) : (
                  <SpeechList speech={speech} />
                )}
              </li>
            ))
          ) : (
            <li>Carregando...</li>
          )}
        </ul>
      </div>
    </div>
  );
};
