import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/navbar.css";
import logo from "../img/logo.svg";
import { BiMenu } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa6";

export const Navbar = () => {
  const [expanded, setExpanded] = useState(false);

  const handleExpanded = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  };

  return (
    <nav id="navbar" className="navbar navbar-expand-lg">
      <div className="container nav-content">
        <Link className="navbar-brand" to={"/"}>
          <img className="navbar-logo" src={logo} alt="Logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded={expanded}
          aria-label="Toggle navigation"
          onClick={handleExpanded}
        >
          {expanded ? (
            <IoMdClose className="dropdownButton" />
          ) : (
            <BiMenu className="dropdownButton" />
          )}
        </button>
        <div
          className={`collapse navbar-collapse ${expanded ? "show" : ""}`}
          id="navbarText"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                onClick={handleExpanded}
                className="nav-link"
                to={"/discursos"}
              >
                Mensagens
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={handleExpanded}
                className="nav-link"
                to={"/atividades"}
              >
                Atividades
              </Link>
            </li>
            <li className="nav-item">
              {/* <Link
                onClick={handleExpanded}
                className="nav-link"
                to={"/historia"}
              >
                História
              </Link> */}
            </li>
            <li className="nav-item">
              <Link
                onClick={handleExpanded}
                className="nav-link"
                to={"/contactos"}
              >
                Contactos
              </Link>
            </li>
            <div className="navbar-social">
              siga nos
              <div className="social-media-icon">
                <Link
                  to={"https://www.instagram.com/estacaportonorte/"}
                  target="_blank"
                  className="link-btn"
                >
                  <FaInstagram className="navbar-icon-btn" />
                </Link>
                <Link
                  to={
                    "https://www.facebook.com/groups/214215472021296/?ref=share_group_link"
                  }
                  target="_blank"
                  className="link-btn"
                >
                  <FaFacebook className="navbar-icon-btn" />
                </Link>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </nav>
  );
};
