import React from "react";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/scrollToTop";
import { BrowserRouter } from "react-router-dom";
import "./styles/layout.css";

import { Navbar } from "./components/navbar.js"
import { Footer } from "./components/footer";
import { Home } from "./pages/home";
import { SpeechId } from "./pages/speechId";
import { Events } from "./pages/events";
import { NewSpeech } from "./pages/newSpeech";
import { Speech } from "./pages/speech";
import { NotFoundPage } from "./pages/notFoundPage";
import { EventId } from "./pages/eventId";
import { LoginPage } from "./pages/loginPage.js";
import { Dashboard } from "./pages/dashboard.js";
import { EditSpeech } from "./pages/editSpeech.js";
import { CreateEvent } from "./pages/createEvent.js";
import { History } from "./pages/History.js";
import { Contact } from "./pages/contact.js";
import { CreateUser } from "./pages/createUser.js";

function MainRoutes() {
  return (
    <div className="layout-fullpage">
        <BrowserRouter>
         <ScrollToTop>
            <div className="layout-navbar">
            <Navbar />
            <div className="layout-content">
                <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/discursos" element={<Speech />} />
                <Route path="/discurso/:id" element={<SpeechId />} />
                <Route path="/criar-atividade" element={<CreateEvent />} />
                <Route path="/atividades" element={<Events />} />
                <Route path="/atividade/:id" element={<EventId />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/dashboard" element={<Dashboard />} />                
                <Route path="/novo-discurso" element={<NewSpeech />} />
                <Route path="/editar-discurso" element={<EditSpeech />} />
                <Route path="/historia" element={<History />} />
                <Route path="/contactos" element={<Contact />} />
                <Route path="/new-user" element={<CreateUser/>}/>
                <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </div>
            <Footer />
            </div>
        </ScrollToTop>
        </BrowserRouter>
    </div>
  );
}

export default MainRoutes;
