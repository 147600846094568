import React, { useState } from "react";
import { format } from "date-fns";
import "../styles/eventCard.css";

import { MdPlace } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { FaPlaceOfWorship } from "react-icons/fa";
// import { FaCircleArrowDown } from "react-icons/fa6";
// import { FaCircleArrowUp } from "react-icons/fa6";

export const EventCard = ({ event }) => {
  // console.log("from eventCard", event);
  const [isExtraVisible, setIsExtraVisible] = useState(false);

  const newDate = (date) => {
    return format(new Date(date), "dd/MM/yyyy");
  };

  const newTime = (time) => {
    return format(new Date(time), "HH:mm");
  };

  const toggleExtraContent = () => {
    setIsExtraVisible(!isExtraVisible);
  };

  return (
    <div className="card-body-content">
      <span className="card-title-button">
        <h3 className="card-event-title">{event.title}</h3>
        <h5 className="card-dropdown-icon">
          {isExtraVisible ? (
            <button
              className="extra-icon-card icon-card"
              onClick={toggleExtraContent}
            >
              Ver menos
            </button>
          ) : (
            // <FaCircleArrowUp
            //   className="extra-icon-card icon-card"
            //   onClick={toggleExtraContent}
            // />
            <button
              className="extra-icon-card icon-card"
              onClick={toggleExtraContent}
            >
              Ver mais
            </button>
            // <FaCircleArrowDown
            //   className="extra-icon-card icon-card"
            //   onClick={toggleExtraContent}
            // />
          )}
        </h5>
      </span>
      <div className="card-text-content"></div>
      <span className={`card-content-extra ${isExtraVisible ? "" : "hidden"}`}>
        <p className="event-card-place">
          <MdPlace className="place icon-card" /> {event.place}
        </p>
        <p className="event-card-time">
          <FaCalendarAlt className="date icon-card" /> {newDate(event.date)} -{" "}
          {newTime(event.date)}
        </p>
        <p className="event-card-organization">
          <FaPlaceOfWorship className="organisation icon-card" />{" "}
          {event.organiser}
        </p>
        <br />
        <div style={{ whiteSpace: "pre-wrap" }}>{event.extraInfo}</div>
      </span>
    </div>
  );
};
