import React from "react";
import "../styles/editSpeech.css";

export const EditSpeech = () => {
  return (
    <div className="text-center mt-5">
      <h1>Editar Discurso</h1>
      <h2>Acessivel apenas pelo dashboard page depois do login</h2>
    </div>
  );
};
