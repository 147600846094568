import React from "react";
import "../styles/notFoundPage.css";

export const NotFoundPage = () => {
  return (
    <div className="text-center mt-5">
      <h1>Página não encontrada</h1>
      <h2>**-**</h2>
    </div>
  );
};
