import React, { useState } from "react";
import "../styles/contact.css";
import emailjs from "@emailjs/browser";
import contactphoto from "../img/home/talktomissionaries.jpg";

export const Contact = () => {
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  function sentEmail(e) {
    e.preventDefault();

    if (name === "" || tel === "" || msg === "") {
      alert("Preencha os campos necessários");
      return;
    }

    const templateParams = {
      name: name,
      email: email,
      message: msg,
      telefone: tel,
    };

    emailjs
      .send(
        "service_msrzlnf",
        "template_svo00od",
        templateParams,
        "aiIlFf1btsmkHLsC7"
      )
      .then(
        (response) => {
          console.log("email enviado", response.status, response.text);
          alert("Mensagem enviada com sucesso");
          setName("");
          setEmail("");
          setMsg("");
          setTel("");
        },
        (err) => {
          console.log("ERRO: ", err);
        }
      );
  }

  return (
    <div className="contact-page">
      <div className="contact-page-header">
        <h1>Envie uma mensagem para a liderança da Estaca</h1>
        <hr />
      </div>
      <div className="contact-body">
        <form className="contact-form" onSubmit={sentEmail}>
          <input
            type="text"
            name="nome"
            placeholder="Nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div>
            <input
              type="tel"
              name="tel"
              placeholder="Número de contacto"
              value={tel}
              onChange={(e) => setTel(e.target.value)}
            />
            <input
              type="email"
              name="email"
              placeholder="E-Mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <textarea
              rows="10"
              cols="60"
              name="texto"
              placeholder="Digite a mensagem aqui"
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
            />
          </div>
          <input type="submit" value="Enviar" />
        </form>
        <img className="contact-img" src={contactphoto} />
      </div>
    </div>
  );
};
