import React, { useState, useEffect } from "react";
import "../styles/dashboard.css";
import stateManager from "../components/stateManager";

export const Dashboard = ({ user, logout }) => {
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    isActive: "yes", // Define o usuário como ativo por padrão
  });

  const [editUser, setEditUser] = useState({
    id: "",
    name: "",
    email: "",
    password: "",
    isActive: "",
  });

  const [users, setUsers] = useState([]);

  useEffect(() => {
    stateManager
      .fetchInfo("users")
      .then((data) => setUsers(data))
      .catch((error) => console.error("Erro ao buscar usuários:", error));
  }, []);

  useEffect(() => {
    if (editUser.id) {
      setNewUser({ ...editUser });
    } else {
      setNewUser({
        name: "",
        email: "",
        password: "",
        isActive: "yes", // Define o usuário como ativo por padrão
      });
    }
  }, [editUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleEdit = (user) => {
    setEditUser(user);
  };

  const handleSave = async () => {
    try {
      if (editUser.id) {
        await stateManager.updateUser(editUser.id, newUser);
        await stateManager.fetchInfo("users");
      } else {
        await stateManager.createUser(newUser);
        await stateManager.fetchInfo("users");
      }
      setEditUser({
        id: "",
        name: "",
        email: "",
        password: "",
        isActive: "",
      });
    } catch (error) {
      console.error("Erro ao salvar usuário:", error);
    }
  };

  return (
    <div>
      <h2>Dashboard</h2>
      <p>Bem-vindo, {user.name}!</p>
      <button onClick={logout}>Logout</button>

      <h3>Novo Usuário</h3>
      <input
        type="text"
        name="name"
        placeholder="Nome"
        value={newUser.name}
        onChange={handleInputChange}
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={newUser.email}
        onChange={handleInputChange}
      />
      <input
        type="password"
        name="password"
        placeholder="Senha"
        value={newUser.password}
        onChange={handleInputChange}
      />
      <select
        name="isActive"
        value={newUser.isActive}
        onChange={handleInputChange}
      >
        <option value="yes">Ativo</option>
        <option value="no">Inativo</option>
      </select>
      <button onClick={handleSave}>
        {editUser.id ? "Salvar Alterações" : "Criar Usuário"}
      </button>

      <h3>Usuários</h3>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            {user.name} - {user.email}{" "}
            <button onClick={() => handleEdit(user)}>Editar</button>
          </li>
        ))}
      </ul>
    </div>
  );
};
