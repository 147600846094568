import React from "react";
import "../styles/footer.css";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-end">
        <p className="footer-disclaimer">
          ESTE NÃO É UM WEBSITE OFICIAL DE A IGREJA DE JESUS CRISTO DOS SANTOS
          DOS ULTIMOS DIAS.
        </p>
        <p className="footer-copyright">
          {" "}
          © 2024 - TODOS OS DIREITOS ESTÃO RESERVADOS A ESTACA PORTO NORTE
        </p>
      </div>
    </footer>
  );
};
