import React, { useEffect, useState } from "react";
import "../styles/speechId.css";
import { useParams } from "react-router-dom";

export const SpeechId = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/data/speech/speech.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        const speechData = jsonData.find(
          (speech) => speech.id === parseInt(id, 10)
        );
        setData(speechData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="text-center mt-5">
      {data ? (
        <div className="speech-id discurso">
          <div className="menu-edit-texto"></div>
          <div className="speech-header">
            <h2 className="speech title">{data.speechTitle}</h2>
            <div className="speech speecher">
              {data.speecher}
              <br />
              {data.speecherTitle}
            </div>
          </div>
          <div className="speech-text">
            <p style={{ whiteSpace: "pre-wrap" }}>{data.speech}</p>
          </div>
        </div>
      ) : (
        <span className="d-flex justify-content-center m-auto">
          Carregando...
        </span>
      )}
    </div>
  );
};
