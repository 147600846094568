import React, { useState } from "react";
import stateManager from "../components/stateManager";

export const CreateUser = () => {
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    isActive: "yes",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSave = async () => {
    console.log(newUser);
    try {
      await stateManager.createUser(newUser);
      setNewUser({
        name: "",
        email: "",
        password: "",
        isActive: "yes",
      });
    } catch (error) {
      console.error("Erro ao criar usuário:", error.message);
    }
  };

  return (
    <div>
      <h2>Criar Novo Usuário</h2>
      <input
        type="text"
        name="name"
        placeholder="Nome"
        value={newUser.name}
        onChange={handleInputChange}
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={newUser.email}
        onChange={handleInputChange}
      />
      <input
        type="password"
        name="password"
        placeholder="Senha"
        value={newUser.password}
        onChange={handleInputChange}
      />
      <select
        name="isActive"
        value={newUser.isActive}
        onChange={handleInputChange}
      >
        <option value="yes">Ativo</option>
        <option value="no">Inativo</option>
      </select>
      <button onClick={handleSave}>Salvar</button>
    </div>
  );
};
