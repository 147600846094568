import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/home.css";
import { FaArrowCircleRight } from "react-icons/fa";
import christIcon from "../img/home/christ-icon.svg";
import calendarIcon from "../img/home/calendar-icon.svg";
import socialIcon from "../img/home/social-icon.svg";
import chapelIcon from "../img/home/chapel-icon.svg";

import logoIcon from "../img/logoBlueNT.svg";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";

// import Swiper JS
import Swiper from "swiper/bundle";
// import Swiper styles
import "swiper/css/bundle";

export const Home = () => {
  useEffect(() => {
    const swiper = new Swiper(".swiper", {
      // Optional parameters
      direction: "horizontal",
      mousewheel: false,
      loop: true,

      autoplay: { delay: 10000 },

      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },

      scrollbar: {
        draggable: true,
      },

      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    // Destroy Swiper instance when the component is unmounted
    return () => {
      swiper.destroy();
    };
  }, []);

  return (
    <div className="home-content">
      <div className="home">
        <div className="swiper swiper-content">
          <div className="swiper-wrapper">
            <div className="swiper-slide slide-one">
              <div className="slide-content">
                <p className="slide-content-title">
                  VIAGEM AO TEMPLO DA ESTACA PORTO NORTE
                </p>
                <p className="slide-content-text">Inscrições abertas</p>

                <Link
                  to={
                    "https://docs.google.com/forms/d/e/1FAIpQLSdQSJjB8rVWv8vFWiEeYAJM-Qty80l4XGUyfK9N_Ay8GdsmxQ/viewform"
                  }
                  className="slide-content-btn"
                >
                  {" "}
                  Inscrever
                </Link>
              </div>
            </div>
            <div className="swiper-slide slide-two">
              <div className="slide-content-two">
                <p className="slide-content-title">
                  ENCONTRE UMA CAPELA PERTO DE VOCE
                </p>
                {/* <p className="slide-content-text">Inscrições abertas</p> */}

                <Link
                  to={"https://maps.churchofjesuschrist.org/"}
                  className="slide-content-btn"
                >
                  {" "}
                  Encontre aqui
                </Link>
              </div>
            </div>
            <div className="swiper-slide slide-three">
              <div className="slide-content">
                <p className="slide-content-title">
                  QUER SABER MAIS SOBRE JESUS CRISTO
                </p>
                <p className="slide-content-text">Contate os missionários</p>

                <Link
                  to={"https://maps.churchofjesuschrist.org/"}
                  className="slide-content-btn"
                >
                  {" "}
                  Mais informação
                </Link>
              </div>
            </div>
          </div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </div>
        {/* <div className="home-cards container">
          <div className="card-um">
            <Link
              to={
                "https://noticias-br.aigrejadejesuscristo.org/artigo/por-que-e-de-que-maneira-os-mormons-sao-diferentes"
              }
              className="card-title"
            >
              Nosas Crenças
            </Link>
          </div>
          <div className="card-dois">
            <hr className="dash" />
            <p className="card-text">
              TODAS AS NOSSAS CRENÇAS SE CONCENTRAM EM JESUS CRISTO
            </p>
            <p className="igreja-nome">
              A Igreja de Jesus Cristo dos Santos dos Ultimos Dias
            </p>
          </div>
        </div> */}
        {/* Descrição dos links e texto da igreja */}
        <div className="home-links">
          <div className="home-desc-text">
            <p>
              Os membros de A Igreja de Jesus Cristo dos Santos dos Últimos Dias
              <br />
              formam uma comunidade diversificada de pessoas em todo o mundo
              <br /> que têm fé em Deus e em Seu Filho, Jesus Cristo.
            </p>
          </div>
          <div className="home-links-cards">
            <Link className="home-card-link qs" to={"/historia"}>
              <div className="card-link">
                <p>Quem somos</p>
                <FaArrowCircleRight />
              </div>
              <div className="link-card-content">
                <div className="link-card-icon">
                  <img
                    alt="Banner "
                    className="link-card-icon"
                    src={christIcon}
                  />
                </div>
                <p>
                  Somos Membros De a Igreja de Jesus Cristo dos Santos dos
                  Últimos Dias, ou Santos dos Ultimos dias...
                </p>
              </div>
            </Link>

            <Link
              to={"https://www.vindeacristo.org/ps/reunir-com-missionarios"}
              className="home-card-link fcm"
            >
              <div className="card-link">
                <p>Fale com os Missionários</p>
                <FaArrowCircleRight />
              </div>
              <div className="link-card-content">
                <div className="link-card-icon">
                  <img
                    alt="Banner "
                    className="link-card-icon"
                    src={socialIcon}
                  />
                </div>
                <p>
                  Saiba mais sobre o Plano de Salvação e converse com os
                  missionários de A Igreja de Jesus Cristo.
                </p>
              </div>
            </Link>

            <Link
              to={"https://maps.churchofjesuschrist.org/"}
              className="home-card-link euc"
            >
              <div className="card-link">
                <p>Encontre uma Capela</p>
                <FaArrowCircleRight />
              </div>
              <div className="link-card-content">
                <div className="link-card-icon">
                  <img
                    alt="Banner "
                    className="link-card-icon"
                    src={chapelIcon}
                  />
                </div>
                <p>
                  Participe conosco de uma reunião de adoração.
                  <br /> Encontre uma capela perto de você.
                </p>
              </div>
            </Link>

            <Link to={"/atividades"} className="home-card-link ede">
              <div className="card-link">
                <p>Eventos da Estaca</p>
                <FaArrowCircleRight />
              </div>
              <div className="link-card-content">
                <div className="link-card-icon">
                  <img
                    alt="Calendario"
                    className="link-card-icon"
                    src={calendarIcon}
                  />
                </div>
                <p>
                  A igreja oferece diversos eventos ao longo do ano, tanto para
                  membros quanto para o público em geral.
                </p>
              </div>
            </Link>
          </div>
        </div>
        {/* divisão de notícia da Estaca */}
        {/* <div className="home-news">
          <h2>Notícia da Estaca</h2>
          <p>
            Bem vindo ao Blog de Notícias da Igreja de Jesus Cristo - Estaca
            Porto Norte!
            <br />
            Este Blog é dedicado a compartilhar notícias e eventos da Igreja de
            Jesus Cristo dos Santos do Últimos Dias na Estaca Porto Norte
          </p>
        </div> */}
        {/* <div>
          <div className="home-news-card">
            <div>imagem</div>
            <div>texto</div>
            <button className="home-btn-card">Ler Mais</button>
          </div>
        </div> */}
        {/* final da pagina home */}
      </div>
      <div className="home-end-content">
        <div className="home-end-logo-div">
          <img alt="Banner" className="home-end-logo" src={logoIcon} />
        </div>
        <div className="home-end-links">
          <Link className="home-end-Link">LIDERNAÇA DA ESTACA</Link>
          <Link className="home-end-Link">HISTÓRIA</Link>
          <Link className="home-end-Link">DISCURSOS</Link>
          <Link className="home-end-Link">CONFERÊNCIA</Link>
          <Link className="home-end-Link">CONTACTOS</Link>
        </div>
        <div className="home-end-snlinks">
          <Link className="link-icon">
            <FaInstagram />
          </Link>
          <Link className="link-icon">
            <FaFacebookSquare />
          </Link>
        </div>
      </div>
    </div>
  );
};
