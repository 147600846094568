import React, { useEffect, useContext } from "react";
import "../styles/eventId.css";

export const EventId = () => {
  return (
    <div className="text-center mt-5">
      <h1>Atividade selecionada :id</h1>
    </div>
  );
};
